.contentBody ::v-deep .el-table td {
  padding: 12px 0;
}

.selectRoom ::v-deep {
  width: 240px;

  .el-input__inner {
    border-radius: 0;
    border-color: #4f9afe;
    color: #4f9afe;
  }
}

.noRecord {
  height: 50px;
  background: #eaf3ff;
  font-size: 13px;
  color: #333;
}

.selectTime {
  height: 32px;
  background: #4f9afe;
  border-radius: 0 16px 16px 0;
  color: #FEFEFE;
  line-height: 32px;
  text-align: center;
  margin-right: 20px;
  min-width: 120px;
}

.calendarRecord {
  height: 50px;
  background: #eaf3ff;
  font-size: 13px;

  p {
    color: #333;
    margin-right: 20px;
    min-width: 100px;
    font-weight: bold;
  }
}

.calendar ::v-deep {
  .el-calendar-table .el-calendar-day {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    padding: 0;
  }

  .el-calendar-table td {
    border: none;
  }

  .el-calendar-table thead th {
    text-align: center;
  }

  .el-calendar__body {
    padding: 12px 20px 0;
  }

  .is-selected span {
    background: #4f9afe;
    border-radius: 50%;
    text-align: center;
    color: #fff !important;
    justify-content: center;
  }

  .current span {
    color: #333;
  }

  .el-calendar-table td.is-selected, .el-calendar-table .el-calendar-day:hover {
    background-color: #FFFFFF !important;
  }

  .is-selected .pastTime {
    color: #4f9afe !important;
    width: 38px !important;
    height: 38px !important;
    border-radius: 50%;
  }

  .current .pastTime {
    background-color: #EAF3FF;
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  .noDa {
    width: 38px;
    height: 38px;
    border: 1px solid #4f9afe;
    border-radius: 50%;
    float: none !important;
    margin: auto;
    color: #4f9afe;
    justify-content: center;
  }

  .error {
    width: 40px;
    height: 40px;
    border: 1px solid red;
    border-radius: 50%;
    float: none !important;
    margin: auto;
    color: red;
    justify-content: center;
  }
}

.timeRange {
  border-color: #4F9AFE;
  border-radius: 0;
  margin-left: 10px;

  .el-range-separator, .el-range__icon, .el-range-input {
    color: #4F9AFE;
  }
}

.keyRecord ::v-deep {
  margin-left: 10px;
  width: 200px;

  .el-input__inner {
    border-color: #4F9AFE;
    border-radius: 0;
  }
}

.search {
  margin-left: 10px;
  background-color: #4F9AFE;
  border-radius: 0;
}

.record-table {
  width: 100%;
  font-size: 14px;
  color: #333;
  margin: 20px 0;
}

.result {
  .time {
    width: 409px;
    height: 37px;
    background: rgba(79, 154, 254, 0.2);
    border-radius: 18px;
    font-size: 16px;
    color: #333;
    text-align: center;
    margin: 27px auto 0;
    line-height: 37px;
  }

  .tableTitle {
    font-size: 16px;
    color: #333;
    margin: 23px auto;
    text-align: center;
  }

  .tableRecord ::v-deep {
    border-width: 2px 2px 0 0;
    border-bottom: 2px solid #4F9AFE;
    border-color: #4F9AFE;

    .tableCellClassName {
      border-width: 0;
      text-align: center;
      color: #333;
    }

    .el-table__body tr:hover > td {
      background-color: inherit;
    }

    .normalColor {
      color: #333;
    }

    .errorColor {
      color: #ED1C24;
    }
  }

}
